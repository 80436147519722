<template>
  <div class="site-list">
    <Toolbar :handle-add="addHandler" />

    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h1>Liste des Sites</h1>
        </v-flex>
        <v-flex lg12>
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <SiteFilterForm ref="filterForm" :values="filters" slot="filter" />
          </DataFilter>

          <br />

          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            item-key="@id"
            show-select
            @update:options="onUpdateOptions"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 100],
            }"
          >

            <ActionCell
              slot="item.action"
              slot-scope="props"
              :handle-show="() => showHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              :handle-delete="() => deleteHandler(props.item)"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import SiteFilterForm from "../../components/site/Filter";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";

export default {
  name: "SiteList",
  servicePrefix: "Site",
  mixins: [ListMixin],
  components: {
    Toolbar,
    ActionCell,
    SiteFilterForm,
    DataFilter
  },
  data() {
    return {
      headers: [
        // { text: 'slug', value: 'slug' },
        { text: "Nom", value: "nom" },
        { text: "Adresse", value: "adresse" },
        { text: "Code Postal", value: "codePostal" },
        { text: "Ville", value: "ville" },
        // { text: 'evenements', value: 'evenement.nom' },
        {
          text: "Actions",
          value: "action",
          sortable: false
        }
      ],
      selected: []
    };
  },
  computed: {
    ...mapGetters("site", {
      items: "list"
    }),
    ...mapFields("site", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view"
    })
  },
  methods: {
    ...mapActions("site", {
      getPage: "fetchAll",
      deleteItem: "del"
    })
  }
};
</script>
